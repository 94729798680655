import {groq} from 'next-sanity';

import type {SanityArray} from '@/sanity/lib/types';

import {CONTENT_BLOCK_FRAGMENT, type ContentBlockFragment} from '../content-blocks/query';
import {PAGE_HEAD_FRAGMENT, type PageHeadFragment} from '../page-head/query';

import type {FrontpageSchema} from './schema';

export const FRONTPAGE_FRAGMENT = groq`
	...,
	pageHeadings[]{
		${PAGE_HEAD_FRAGMENT}
	},
	content[]{
		${CONTENT_BLOCK_FRAGMENT}
	},
`;

export type FrontpageFragment = Omit<FrontpageSchema, 'content' | 'pageHeadings'> & {
	pageHeadings?: SanityArray<PageHeadFragment>;
	content?: SanityArray<ContentBlockFragment>;
};

/**
 * Fetches the frontpage.
 */
export const FRONTPAGE_QUERY = groq`
*[_type == "frontpage"][0]{
	${FRONTPAGE_FRAGMENT}
}
`;

export type FrontPageQueryResult = FrontpageFragment | null;
