import ContentBlocks from '@/sanity/components/content-blocks/ContentBlocks';
import PageHead from '@/sanity/components/page-head/PageHead';

import type {FrontPageQueryResult} from './query';

interface Props {
	page: FrontPageQueryResult;
}

export default function FrontPage({page}: Props) {
	const {content, pageHeadings} = page ?? {};

	return (
		<>
			{pageHeadings && <PageHead pageHeads={pageHeadings} />}
			{content && <ContentBlocks content={content} />}
		</>
	);
}
